.auth-container {
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    /* background-color: var(--light-gray); */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .auth-title {
    text-align: center;
    margin-bottom: 20px;
    color: var(--dark-purple);
    font-weight: bold;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .colored-text {
    color: var(--primary-purple) !important;
    margin-top: 3% !important;
    font-weight: 400 !important;
  }
  