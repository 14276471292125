.footer {
  background-color: var(--dark-purple) !important;
  padding: 30px 0 !important;
  color: var(--light-gray) !important;
}

.footer-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.footer-links {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
}

.footer-link-header {
  font-weight: bold !important;
  margin-bottom: 10px !important;
  color: var(--light-gray) !important;
}

.footer-contact {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.footer-icon {
  color: var(--primary-color) !important;
  font-size: 24px !important;
}

.footer-logo-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center !important;
  margin-top: 120px !important;
}

.footer-logo {
  height: 60px !important;
  margin-bottom: 10px !important;
  background-color: white !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.footer-copyright {
  font-size: 0.9rem !important;
  color: var(--light-gray) !important;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column !important;
    text-align: center !important;
  }

  .footer-links {
    align-items: center !important;
  }

  .footer-contact {
    width: 100% !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 10px !important;
  }

  .footer-logo-container { 
    margin-top: 50px !important;
  }
}
