.welcome-section {
    text-align: center !important;
    margin-bottom: 60px !important;
  }
  
  .welcome-text {
    color: var(--dark-purple) !important;
    margin-bottom: 20px !important;
    font-weight: bold !important;
    /* text-align: start !important; */
  }
  
  .description-text {
    font-size: 1.4rem !important;
    line-height: 1.8 !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    margin: 0 auto 40px auto !important;
    margin: 0px !important;
    max-width: 1000px !important;
    /* text-align: start !important; */
  }
  