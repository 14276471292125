
.about-us-page-container {
    /* padding: 40px 20px !important; */
    /* background: var(--light-gray) !important; */
    margin-top: 100px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100vh !important;
}

.about-us-content {
    padding: 40px !important;
    padding-bottom: 100px !important;
    background-color: white !important;
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important; */
    border-radius: 18px !important;
    width: 100% !important;
    /* max-width: 1400px !important; */
}

.about-us-title {
    text-align: center !important;
    margin-bottom: 30px !important;
    font-weight: bold !important;
    color: var(--dark-purple) !important;
}

.primary-text {
    color: var(--primary-purple) !important;
}

.section-title {
    margin-bottom: 20px !important;
    color: var(--primary-purple) !important;
    text-align: center !important;
    font-weight: bold !important;
    /* background: linear-gradient(135deg, #231459, #3d1e8b, #5b38b0, #7d5fd6) !important; */
    padding: 10px !important;
    border-radius: 8px !important;
}

.section-text {
    margin-bottom: 20px !important;
    line-height: 1.8 !important;
    text-align: justify !important;
    padding: 0 20px !important;
    font-size: 1.2rem !important;
}

.section-image {
    width: 100% !important;
    height: auto !important;
    max-width: 453px !important;
    margin-top: 10px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 12px !important;
}

.text-section, .image-section {
    display: flex !important;
    flex-direction: column !important; /* Ensures the title and text are stacked vertically */
    justify-content: center !important;
    align-items: center !important;
}

.section-title {
    margin-bottom: 10px !important; /* Adjust margin for better spacing */
    font-size: 2rem !important;
}

@media (max-width: 768px) {
    .section-text {
        text-align: center !important;
        padding: 0 10px !important;
    }
}