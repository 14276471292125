.services-section {
  /* text-align: center !important; */
  margin-bottom: 60px !important;
}

.services-title {
  margin-bottom: 20px !important;
  font-weight: bold !important;
  font-size: 2.3rem !important;
  color: rgb(41, 41, 41) !important;
}

.services-description-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-bottom: 20px !important;
}

.services-description {
  font-size: 1.4rem !important;
  line-height: 1.8 !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
  /* max-width: 1000px !important; */
  text-align: start !important;
  position: relative !important;
  background: var(--light-gray) !important;
  transition: transform 0.3s ease !important;
}

.services-description:hover {
  transform: scale(1.02) !important;
}

.highlight {
  color: var(--primary-purple) !important;
  font-weight: bold !important;
}

.services-slider {
  margin-bottom: 40px !important;
}

.slide {
  display: flex !important;
  justify-content: center !important;
  height: auto !important;
  text-overflow: clip !important;
}


.get-started-button {
  display: flex !important;
  width: 15% !important;
  background-color: var(--primary-purple) !important;
  color: var(--light-gray) !important;
  font-weight: bold !important;
  margin: auto !important;
  margin-top: 20px !important;
}

.get-started-button:hover {
  background-color: var(--deep-purple) !important;
}

/* Responsive styles */
@media (max-width: 1060px) {
  .services-description {
      font-size: 1.2rem !important;
      padding: 8px 16px !important;
  }
  .get-started-button {
    width: 30% !important;
  }

  .highlight {
      font-size: 1.2rem !important;
  }
}

@media (max-width: 570px) {
  .get-started-button {
    width: 40% !important;
  }
}

@media (max-width: 450px) {
  .get-started-button {
    width: 70% !important;
  }
}