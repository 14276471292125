.features-section {
    text-align: center !important;
    margin-bottom: 60px !important;
  }
  
  .features-title {
    /* color: var(--dark-purple) !important; */
    margin-bottom: 20px !important;
    font-weight: bold !important;
    text-align: start !important;
  }
  