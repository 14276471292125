@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BuenosAires';
    src: url('../fonts/BuenosAires/BuenosAires-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
