.research-form-container {
    margin: auto !important;
    margin-top: 150px !important;
    margin-bottom: 100px !important;
    max-width: 1200px !important;
}

.page-title {
    margin-bottom: 20px !important;
    color: var(--dark-purple) !important;
    font-weight: bold !important;
    text-align: left !important; 
}

.back-button {
    color: var(--primary-purple) !important;
    border-color: var(--dark-purple) !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-family: 'BuenosAires, sans-serif' !important;
}

.back-button:hover {
    background-color: var(--dark-purple) !important;
    color: white !important;
}

.introduction-text {
    padding-left: 3% !important;
    padding-right: 3% !important;
    margin-bottom: 20px !important;
    text-align: start !important;
    line-height: 1.6 !important;
    color: #444 !important;
    font-size: 1.1rem !important;
    font-family: 'BuenosAires, sans-serif' !important;
}

.introduction-text strong {
    font-weight: bold !important;
    color: var(--dark-purple) !important;
}

.introduction-text em {
    font-style: italic !important;
}

.introduction-text .highlight {
    color: var(--primary-purple) !important;
    padding: 2px 4px !important;
    border-radius: 4px !important;
}

.bigger-text {
    font-size: 1.3rem !important;
}

.research-form {
    padding: 16px !important;
    background-color: #f9f9f9 !important;
    border-radius: 12px !important;
}

.file-upload-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.file-upload-button {
    color: var(--dark-purple) !important;
    border-color: var(--dark-purple) !important;
    font-family: 'BuenosAires, sans-serif' !important;
    text-transform: none !important;
}

.file-name {
    font-family: 'BuenosAires, sans-serif' !important;
    color: var(--dark-purple) !important;
}

.research-submit-button {
    background-color: var(--primary-purple) !important;
    color: white !important;
    font-size: 1rem !important;
    width: 50% !important;
    display: flex !important;
    margin: auto !important;
    border-radius: 30px !important;
}

.research-submit-button:hover {
    background-color: var(--dark-purple) !important;
}

.error-text {
    color: red !important;
    font-family: 'BuenosAires, sans-serif' !important;
}

/* Responsive layout for smaller screens */
@media (max-width: 600px) {
    .responsive-box {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .page-title,
    .back-button {
        width: 100% !important;
        text-align: center !important;
        margin-bottom: 10px !important;
    }

    .introduction-text {
        font-size: 0.9rem !important;
    }

    .bigger-text {
        font-size: 1.1rem !important;
    }
}
