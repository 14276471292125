.top-banner {
  /* background-color: var(--dark-purple) !important; */
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 70vh !important;
  padding: 20px !important;
  transition: height 0.3s ease !important;
  background:
    linear-gradient(rgba(255, 255, 255, 0.107), rgba(255, 255, 255, 0.063)),
    repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px,
      transparent 10px
    ),
    linear-gradient(135deg, var(--dark-purple) 0%, var(--deep-purple) 45%, var(--primary-purple) 100%) !important;
  background-blend-mode: overlay !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.top-banner-scrolled {
  height: 50vh !important; /* Adjusted height when scrolled */
}

.banner-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  /* max-width: 1200px !important; */
}

.banner-text {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center !important;
}

.banner-line {
  margin: 5px 0 !important;
  color: white !important;
}

.banner-logo .logo {
  height: 150px !important;
  transition: height 0.3s ease !important;
}

.banner-logo img {
  height: 200px !important;
  background-color: white !important;
  padding: 5px !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.hidden {
  display: none !important;
}

@media (max-width: 1000px) { 
  .banner-line {
    font-size: 1.7rem !important;
  }
}

@media (max-width: 768px) {
  .top-banner {
    height: auto !important;
    padding: 40px 20px !important;
    margin: 40px 0px;
  }

  .banner-container {
    flex-direction: column !important;
    text-align: center !important;
  }

  .banner-logo img {
    height: 100px !important;
    margin-bottom: 20px !important;
  }

  .banner-text {
    text-align: center !important;
  }

  .banner-logo .logo {
    margin-top: 10% !important;
    max-width: 200px !important;
    height: 100px !important;
  }

  .banner-line {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 480px) {
  .banner-line {
    font-size: 1.5rem !important;
  }
}
