.school-services-container {
    padding: 40px 20px !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
    margin-top: 90px !important;
}

.school-services-title {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    color: var(--primary-purple) !important;
    text-align: center !important;
    margin-bottom: 40px !important;
}

.school-services-description {
    border-radius: 12px !important;
    margin-bottom: 40px !important;
}

.page-description {
    background-color: #f5f5f5 !important;
    border-left: 5px solid var(--primary-purple-shade) !important;
    padding: 20px !important;
    margin-bottom: 30px !important;
    font-size: 1.2rem !important;
    color: #333 !important;
    line-height: 1.8 !important;
}

.school-service-item {
    margin-bottom: 20px !important;
}

.school-service-item strong {
    color: var(--dark-purple) !important;
    font-size: 1.2rem !important;
}

.school-service-item p {
    color: #666 !important;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

.app-link {
    /* display: block !important; */
    margin-top: 10px !important;
    color: var(--primary-purple) !important;
    font-weight: bold !important;
    text-decoration: none !important;
  }
  
  .app-link:hover {
    text-decoration: underline !important;
    color: var(--dark-purple) !important;
  }

.page-footer-text {
    background-color: #f0f0f0 !important;
    border-top: 3px solid var(--primary-purple-shade) !important;
    padding: 20px !important;
    border-radius: 0 0 12px 12px !important;
    font-size: 1.1rem !important;
    color: #444 !important;
    text-align: center !important;
    margin-top: 30px !important;
}

@media (max-width: 768px) {
    .school-services-container {
        padding: 30px 15px !important;
    }

    .school-services-title {
        font-size: 2rem !important;
    }

    .school-services-description {
        padding: 20px !important;
    }

    .school-service-item strong {
        font-size: 1.1rem !important;
    }

    .school-service-item p {
        font-size: 0.9rem !important;
    }

    .page-footer-text {
        font-size: 1rem !important;
    }
}

@media (max-width: 480px) {
    .school-services-container {
        padding: 20px 10px !important;
    }

    .school-services-title {
        font-size: 1.8rem !important;
    }

    .school-services-description {
        padding: 15px !important;
    }

    .school-service-item strong {
        font-size: 1rem !important;
    }

    .school-service-item p {
        font-size: 0.85rem !important;
    }

    .page-footer-text {
        font-size: 0.95rem !important;
        padding: 15px !important;
    }
}
