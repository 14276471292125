/* src/pages/auth/AuthPage.css */
.auth-page {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: white !important;
  margin-top: 12% !important;
  margin-bottom: 5% !important;
  padding: 5% 2% !important;
  border-radius: 30px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.307);
  max-width: 45% !important;
  /* width: 100% !important; */
}

.auth-title {
  text-align: center !important;
  margin-bottom: 20px !important;
  color: var(--dark-purple) !important;
  font-weight: bold !important;
}

.auth-subtitle {
  text-align: center !important;
  margin-bottom: 20px !important;
  /* color: var(--dark-purple) !important; */
  /* font-weight: bold !important; */
  font-size: 1.2rem !important;
}


@media (max-width: 992px) {
  .auth-page {
    max-width: 70% !important;
    margin-top: 13% !important;
  }
  .auth-title { 
    font-size: 1.6rem !important;
  }

  .auth-subtitle {
    font-size: 1rem !important;
  }
}

@media (max-width: 768px) {
  .auth-page {
    max-width: 80% !important;
    padding: 10% 5% !important;
    margin-top: 13% !important;
  }
  .auth-title { 
    font-size: 1.5rem !important;
  }

  .auth-subtitle {
    font-size: 1rem !important;
  }
}

@media (max-width: 576px) {
  .auth-page {
    max-width: 90% !important;
    padding: 10% 3% !important;
    margin-top: 25% !important;
  }
  .auth-title { 
    font-size: 1.5rem !important;
  }

  .auth-subtitle {
    font-size: .8rem !important;
  }
}
