.contact-page-container {
    margin: auto !important;
    /* margin-top: 100px !important; */
    margin-bottom: 100px !important;
    padding: 20px !important;
    max-width: 1200px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 20px !important;
}

.contact-intro-text {
    color: var(--primary-purple) !important;
    text-align: center !important;
    font-size: 2rem !important;
    margin-top: 50px !important;
    margin-bottom: 10px !important;
}


.contact-info-container,
.contact-form-container {
    flex: 1 1 45% !important; /* Take up half the space on larger screens */
    min-width: 300px !important; /* Ensure a minimum width */
    background-color: #f9f9f9 !important;
    border-radius: 12px !important;
    padding: 20px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    height: 450px !important;
}

.contact-info {
    padding: 20px !important;
}

.contact-info-item {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px !important;
}

.contact-title {
    color: var(--dark-purple) !important;
    font-weight: bold !important;
    text-align: center !important;
    font-size: 1.4rem !important;
}

.contact-detail {
    margin: 10px 0 !important;
    color: #444 !important;
}

.contact-icon {
    font-size: 2rem !important;
    color: var(--primary-purple) !important;
    margin-right: 15px !important;
}

.contact-subtitle {
    font-weight: bold !important;
    font-size: 1rem !important;
    color: var(--dark-purple) !important;
}

.contact-data {
    font-size: 0.9rem !important;
    color: #444 !important;
}

.form-title {
    margin-bottom: 20px !important;
    color: var(--dark-purple) !important;
    font-family: 'BuenosAires, sans-serif' !important;
}

.contact-form {
    width: 100% !important;
}

.contact-submit-button {
    background-color: var(--primary-purple) !important;
    color: white !important;
    font-size: 1rem !important;
    width: 50% !important;
    display: flex !important;
    margin: auto !important;
    border-radius: 30px !important;
}

.contact-submit-button:hover {
    background-color: var(--dark-purple) !important;
}

.social-media-icons {
    margin-top: 20px !important;
}

.social-icon {
    color: var(--primary-purple) !important;
    font-size: 2rem !important;
}

.social-icon:hover {
    color: var(--dark-purple) !important;
}


.map-section {
    width: 100% !important; 
    margin-top: 20px !important;
}

.map {
    width: 100% !important; 
    height: 400px !important; 
    border: 0 !important; 
}

    
@media (max-width: 768px) {
    .contact-page-container {
        flex-direction: column !important;
    }

    .contact-intro-text {
        font-size: 1.5rem !important;
     }
}
    
@media (max-width: 600px) {
    .contact-intro-text {
        font-size: 1.3rem !important;
     }
}
    
@media (max-width: 400px) {
    .contact-intro-text {
        font-size: 1.2rem !important;
     }
}
