.top-image-container {
  width: 100%;
  overflow: hidden;
}

.top-image {
  width: 100%;
  height: 75vh;
}

.home-container {
  padding: 40px 20px !important;
  /* max-width: 1200px !important; */
  margin: 0 auto !important;
}


.section-title {
  text-align: center !important;
  /* color: var(--dark-purple) !important; */
  margin-bottom: 20px !important;
  font-weight: bold !important;
  text-align: start !important;
}

.services-title {
  margin-bottom: 20px !important;
  font-weight: bold !important;
  font-size: 2.3rem !important;
  color: rgb(41, 41, 41) !important;
}