.modal-box {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  padding: 40px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15) !important;
  max-width: 500px !important;
  width: 100% !important;
}

.modal-title {
  margin-bottom: 20px !important;
  text-align: center !important;
  color: var(--dark-purple) !important;
}

.modal-subtitl {
  margin: 2% !important;
  text-align: center !important;
  color: var(--primary-purple) !important;
}

.modal-description {
  margin-bottom: 20px !important;
  text-align: start !important;
  line-height: 1.6 !important;
  color: #444 !important;
  font-size: 1.1rem !important;
  font-family: 'BuenosAires, sans-serif' !important;
}

.modal-description strong {
  font-weight: bold !important;
  color: var(--dark-purple) !important;
}

.modal-description em {
  font-style: italic !important;
}

.modal-description .highlight {
  color: var(--primary-purple) !important;
  padding: 2px 4px !important;
  border-radius: 4px !important;
}

.bigger-text {
  font-size: 1.3rem !important;
}

.month-select {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.submit-button {
  background-color: var(--primary-purple) !important;
  color: white !important;
  width: 100% !important;
}

.submit-button:hover {
  background-color: var(--dark-purple) !important;
  color: white !important;
}

@media (max-width: 900px) {
  .modal-box {
      padding: 20px !important; 
  }

  .modal-description {
      font-size: 1rem !important; 
  }

  .bigger-text {
      font-size: 1.2rem !important;
  }

  .submit-button {
      font-size: 0.9rem !important; 
  }
}
@media (max-width: 600px) {
  .modal-box {
      padding: 20px !important; 
      max-width: 90% !important;
  }

  .modal-description {
      font-size: .9rem !important;
  }

  .bigger-text {
      font-size: 1rem !important;
  }
  .modal-description .highlight {
      font-size: 1rem !important;
  }

  .submit-button {
      font-size: 0.8rem !important;
  }
}
