.feature-card {
    padding: 20px !important;
    background-color: var(--dark-purple) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    text-align: center !important;
    transition: transform 0.3s !important;
    height: 200px !important;
  }
  
  .feature-card:hover {
    transform: translateY(-10px) !important;
  }
  
  .feature-icon {
    font-size: 50px !important;
    color: var(--primary-purple) !important;
    margin-bottom: 10px !important;
  }
  
  .feature-name {
    margin-top: 10px !important;
    color: var(--light-gray) !important;
    font-weight: bold !important;
  }
  
  .feature-text {
    margin-top: 10px !important;
    color: var(--light-gray) !important;
  }
  