.other-services-container {
  margin-top: 20px !important;
  padding: 20px !important;
}

.page-intro {
  text-align: center !important;
  margin-bottom: 20px !important;
}

.service-cards {
  display: flex !important;
  justify-content: center !important;
  gap: 20px !important;
  flex-wrap: wrap !important;
}

.service-card {
  display: flex !important;
  justify-content: center !important;
  width: 300px !important;
}

@media (max-width: 600px) {
  .service-cards {
      flex-direction: column !important;
      align-items: center !important;
  }

  .service-card {
      width: 100% !important; 
      max-width: 300px !important; 
  }
}
