.auth-container {
    margin: 0 auto !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
  }
  
  .auth-title {
    text-align: center !important;
    margin-bottom: 20px !important;
    color: var(--dark-purple) !important;
    font-weight: bold !important;
  }
  
  .auth-form {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    max-width: 80% !important;
  }
  
  .forgot-password {
    text-align: right !important;
  }
  
  .auth-links {
    color: var(--primary-purple) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-button-link {
    padding: 0;
    text-transform: none;
    font-size: inherit;
    font-weight: bold;
  } 


@media (max-width: 992px) {
  
}

@media (max-width: 768px) {
  
}

@media (max-width: 576px) {
  
}
