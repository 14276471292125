
.cover-image-container {
    margin-top: 100px;
    height: 50dvh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    opacity: 0; /* Start with opacity 0 */
    animation: fadeIn .5s ease-in-out forwards; /* Add animation */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.cover-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

