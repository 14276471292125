.header {
  background-color: transparent !important;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 10 !important;
}

.header-scrolled {
  background-color: white !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.logo {
  height: 80px !important;
  margin: 0 1% !important;
}

.logo-hidden {
  display: none !important;
}

.toolbar {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  padding: 0 !important;
}

.left-section {
  display: flex !important;
  align-items: center !important;
  gap: 1rem !important;
  width: 80% !important;
}

.nav-links {
  display: flex !important;
  gap: 1rem !important;
}

.nav-links a, .nav-links button {
  color: var(--light-gray) !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  transition: color 0.3s ease;
  font-family: var(--font-title) !important;
}

.header-scrolled .nav-links a, .header-scrolled .nav-links button {
  color: var(--dark-purple) !important;
}

.nav-links a:hover, .nav-links button:hover {
  color: var(--primary-purple) !important;
  background: none !important;
}

.right-section {
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important; /* Add this line */
}


.login-button {
  color: white !important;
  background-color: var(--primary-purple) !important;
  font-size: 1rem !important; /* Reduced for scalability */
  text-transform: capitalize !important;
  border-radius: 108px !important;
  padding: 8px 20px !important; /* More flexible padding */
  margin-left: 10px !important;
}

.login-button:hover {
  background-color: var(--dark-purple) !important;
}



.menu-button {
  display: none !important;
}

.drawer, .MuiDrawer-paper {
  width: 50% !important;
  color: var(--deep-purple) !important;
}

.drawer .MuiListItemText-root {
  color: var(--dark-purple) !important;
}

.menu {
  margin-top: 8px;
  padding: 0;
}
#profile-menu {
  margin-top: 20px !important;

}

.menu-open::after {
  content: '' !important;
  display: block !important;
  width: 90% !important;
  height: 2px !important;
  background-color: var(--primary-purple) !important;
  position: absolute !important;
  bottom: -30px !important;
  left: 3px !important;
}

.nav-links button[aria-controls="services-menu"] {
  line-height: normal !important; /* Ensure consistent line height */
  height: 100% !important; /* Keep the height stable */
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  padding-bottom: 0 !important; /* Prevent padding from causing shift */
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  vertical-align: top !important;
  align-items: start !important;
}

.soon-badge {
  background-color: rgb(238, 159, 14) !important;
  color: white !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  padding: 2px 6px !important;
  border-radius: 12px !important;
  margin-left: 8px !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

.menu .MuiMenuItem-root {
  font-family: var(--font-title) !important;
  color: var(--dark-purple);
  position: relative;
}

.drawer .MuiListItemText-root:hover {
  color: var(--primary-purple) !important;
  background: none;
}

.MuiMenu-list {
  color: var(--dark-purple) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  width: 220px !important;
}

.menu .MuiMenuItem-root {
  color: var(--dark-purple) !important;
  position: relative;
}

.menu .MuiMenuItem-root:hover {
  color: var(--primary-purple) !important;
  background: none !important;
}

.menu-item {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}

.menu-item.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.menu-item .soon-badge {
  margin-left: auto !important;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-left: 16px; /* adjust the margin value as needed */
  z-index: 1;
}



@media (max-width: 768px) {
  .nav-links {
      display: none !important;
  }

  .menu-button {
      display: block !important;
      margin-right: auto !important;
      color: var(--primary-purple) !important;
      align-self: center !important;
  }

  .logo {
      height: 45px !important;
  }

  .MuiToolbar-root {
      justify-content: start !important;
  }

  .login-button, .logged-in-icon {
      margin: auto !important;
      margin-right: 20px !important; 
  }

  .right-section {
      flex-grow: 1 !important;
      justify-content: flex-end !important;
      align-items: center !important;
  }

  .menu {
      margin-top: 10px !important;
  }
  
  #profile-menu {
      margin-top: 8px !important;
  }
}