.alert-modal-container {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 400px !important;
    background-color: white !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    padding: 16px !important;
    outline: none !important;
    text-align: center !important;
  }
  
  .alert-modal-title {
    font-size: 1.4rem !important;
    margin-bottom: 8px !important;
    color: var(--dark-purple) !important;
  }
  
  .alert-modal-message {
    margin-bottom: 16px !important;
    font-family: 'BuenosAires, sans-serif' !important;
  }
  
  .alert-modal-button {
    background-color: var(--primary-purple) !important;
    color: white !important;
    font-weight: bold !important;
    font-family: 'BuenosAires, sans-serif' !important;
    text-transform: none !important;
  }
  
  .alert-modal-button:hover {
    background-color: var(--dark-purple) !important;
  }
  
  /* Styles for different alert types */
  .success {
    border-left: 6px solid green !important;
  }
  
  .error {
    border-left: 6px solid red !important;
  }
  
  .warning {
    border-left: 6px solid orange !important;
  }
  