.category-list-container {
  overflow: hidden !important;
  width: 95% !important;
  margin: auto !important;
}

.page-intro {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin: auto !important;
  text-align: center !important;
}

.list-title {
  font-weight: bold !important;
  font-size: 2.3rem !important;
  color: rgb(41, 41, 41) !important;
  /* text-align: start !important;
  margin-left: 5% !important; */
}

.category-slider .slide {
  padding: 10px !important;
}

.slick-prev, .slick-next {
  z-index: 1 !important;
}


.slick-dots {
  /* display: block !important; */
  bottom: -25px !important;
  position: static !important;
}

.slick-dots li button:before {
  color: var(--primary-purple) !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: var(--dark-purple) !important;
}

@media (max-width: 600px) {
  .page-intro {
    margin-top: 15% !important;
  }
}
