.course-list {
  text-align: center !important;
  width: 90% !important;
  margin: auto !important;
}

.category-header {
  padding-left: 2% !important;
  padding-right: 2% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  /* margin-bottom: 20px !important; */
}

.back-button {
  color: var(--primary-purple) !important;
  font-size: 1.1rem !important;
  padding: 1% !important;
}

.back-button:hover {
  color: white !important;
  background-color: var(--dark-purple) !important;
}

.category-title {
  /* margin-bottom: 20px !important; */
  font-weight: bold !important;
  font-size: 2.3rem !important;
  color: rgb(41, 41, 41) !important;
}

.intro-text {
  margin: 20px 0 !important;
  text-align: center !important;
  line-height: 1.8 !important;
  /* color: #333 !important;  */
  font-size: 1.2rem !important;
  /* background-color: #f0f0f0 !important;  */
  padding: 20px !important;
  border-radius: 10px !important;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important; */
}

.intro-header {
  font-size: 1.4rem !important;
  color: var(--dark-purple) !important;
  padding-bottom: 10px !important;
  border-bottom: 2px solid var(--dark-purple) !important;
  margin-bottom: 10px !important;
}

.intro-footer {
  font-size: 1.3rem !important;
  color: var(--primary-purple) !important;
  margin-top: 10px !important;
}

.intro-highlight {
  color: var(--primary-purple) !important;
  /* color: white !important; */
  padding: 4px 6px !important;
  border-radius: 5px !important;
  font-size: 1.5rem !important;
}


.course-slider .slide {
  padding: 10px !important;
}

.slick-prev, .slick-next {
  z-index: 1 !important;
}

.slick-dots {
  bottom: -25px !important;
  position: static !important;
}

.slick-dots li button:before {
  color: var(--primary-purple) !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: var(--dark-purple) !important;
}

.courses-container {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  cursor: pointer !important;
}

@media (max-width: 1000px) {
  .category-header  {
      margin-top: 10% !important;
  }
}
/* Responsive layout for smaller screens */
@media (max-width: 690px) {
  .responsive-box {
      flex-direction: column !important;
      align-items: flex-start !important;
  }

  .page-title,
  .back-button {
      width: 100% !important;
      text-align: center !important;
      margin-bottom: 10px !important;
  }
}
