.course-card {
  background-color: white !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  transition: transform 0.3s !important;
  height: auto !important;
  width: auto !important; 
  display: flex !important;
  min-width: 250px !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding: 15px !important;
  margin: 10px !important;
  cursor: pointer !important;
}

.course-card:hover {
  transform: translateY(-10px) !important;
}

.course-icon-top {
  height: 40px !important;
  width: 40px !important;
  margin-bottom: 10% !important;
}

.course-icon-centered {
  height: 80px !important;
  width: 80px !important;
  margin-bottom: 10% !important;
  border-radius: 5px !important;
}

.course-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.small {
  width: 40px !important;
}

.course-name {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: black !important;
  margin-bottom: 10px !important;
  text-align: start !important;
}

.course-description {
  font-size: 1rem !important;
  /* color: var(--deep-purple) !important; */
  margin-bottom: 20px !important;
  text-align: start !important;
}

.learn-more-button {
  color: var(--primary-purple) !important;
  font-weight: bold !important;
  text-align: left !important;
  padding-left: 0 !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.learn-more-button:hover {
  color: white !important;
  background-color: var(--dark-purple) !important;
}

@media (max-width: 600px) {
  .course-card {
    width: 80% !important;
    margin: auto !important;
  }
  .learn-more-button {
    position: relative !important;
  }
  .css-46bh2p-MuiCardContent-root {
    padding-bottom: 10px !important;
  }
}
