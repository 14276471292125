/* AIMIntroduction.css */

.aim-section-header {
    display: flex !important;
    /* align-items: center !important; */
    /* justify-content: center !important; */
    gap: 10px !important;
    margin-bottom: 20px !important;
  }
  
  .aim-section-title {
    text-align: center !important;
    font-weight: bold !important;
    font-size: 1.8rem !important;
  }
  
  .new-badge {
    background-color: rgb(238, 159, 14) !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    border-radius: 8px !important;
    padding: 1px 3px !important;
  }
  
  /* AIM Section Styles */
  .aim-introduction-section {
    margin: 20px auto !important;
    padding: 40px 20px !important;
    background: linear-gradient(135deg, var(--primary-purple), var(--deep-purple)) !important;
    color: var(--light-gray) !important;
    border-radius: 16px !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1) !important;
  }
  
  
  .aim-image-card {
    max-width: 100% !important;
    border-radius: 16px !important;
    overflow: hidden !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1) !important;
    margin-left: 10px !important;
  }
  
  .aim-image {
    width: 100% !important;
    height: auto !important;
  }
  
  .aim-text-content {
    text-align: left !important;
    padding: 20px !important;
  }
  
  .aim-title {
    font-size: 2rem !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    color: var(--light-gray) !important;
  }
  
  .aim-description {
    font-size: 1.2rem !important;
    line-height: 1.8 !important;
    margin-bottom: 30px !important;
    color: #e0e0e0 !important;
  }
  
  .aim-app-link-button {
    background-color: var(--primary-purple) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    padding: 12px 24px !important;
    border-radius: 8px !important;
    transition: all 0.3s ease-in-out !important;
  }
  
  .aim-app-link-button:hover {
    background-color: white !important;
    transform: translateY(-3px) !important;
    color: var(--primary-purple) !important;
  }

  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .aim-title {
      font-size: 1.8rem !important;
    }
  
    .aim-description {
      font-size: 1rem !important;
    }
  
    .aim-app-link-button {
      font-size: 0.9rem !important;
      padding: 10px 20px !important;
    }
    .aim-section-title {
      font-size: 1.2rem !important;
    }
  }
  
  @media (max-width: 400px) { 
    .aim-section-title {
      font-size: 1rem !important;
    }

  }
  